@import 'mediaQueries';

html {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";;
    color: #24292e;

    @include phones-and-down() {
        font-size: 15px !important;
        line-height: 1.5 !important;
    }
    @include tablets-only {
        font-size: 15px !important;
        line-height: 1.5 !important;
    }

    @include desktops-and-up {
        font-size: 16px !important;
        line-height: 1.7 !important;
    }

    letter-spacing: 0.2px;
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
}

.text-center {
    text-align: center;
}

.text-faded {
    color: #929fae;
}

.text-sm {
    font-size: 11px;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: none;
    }
}

.link {
    color: #8E6DFF;
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}

// Generates margin and padding helper classnames like `p1`, `pt2`, etc...
$spaceamounts: (1, 2, 3, 4, 5);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
    .m#{$space} {
        margin: #{$space * 5}px !important;
    }

    .p#{$space} {
        padding: #{$space * 5}px !important;
    }

    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}#{$space} {
            margin-#{$side}: #{$space * 5}px !important;
        }

        .p#{str-slice($side, 0, 1)}#{$space} {
            padding-#{$side}: #{$space * 5}px !important;
        }
    }
}

.container-lg {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}

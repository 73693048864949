
@mixin phones-and-up {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin tablets-and-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktops-and-up {
  @media (min-width: 992px) {
    @content;
  }
}

@mixin lg-desktops {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin sm-phones-and-down {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin phones-and-down {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin tablets-and-down {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin desktops-and-down {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin sm-phones {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin phones-only {
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin tablets-only {
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin desktops-only {
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content;
  }
}
